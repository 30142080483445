@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800;900&family=Source+Code+Pro:wght@400&display=swap");

:root {
	/*
		app config
	*/
	--app-theme-value: 13, 104, 216;
	--app-theme-opacity: 0.5;
	--app-background: hsl(230, 36%, 4%);
	--app-background-transparent: hsla(230, 36%, 4%, 85%);
	--app-text: hsl(0, 0%, 100%);
	--app-theme-primary: rgb(var(--app-theme-value));
	--app-theme-secondary: rgba(12, 57, 172, 0.85);
	--app-theme-primary-transparent: rgba(
		var(--app-theme-value),
		var(--app-theme-opacity)
	);
	--app-theme-primary-dark: rgba(var(--app-theme-value), 0.1);
	--app-theme-primary-disabled: rgba(40, 93, 158, 0.823);
	--app-container-bg-primary: hsl(240, 10%, 16%);
	--app-container-text-primary: rgb(120, 121, 135);
	--app-container-text-primary-hover: hsl(240, 6%, 75%);
	--app-container-bg-secondary: hsl(236, 10%, 23%);
	--app-container-check: #0dbe00;
	--app-modal-btn-primary: hsl(225, 14%, 9%);

	--wave-start-opacity: 0.6;

	--local-vignette: radial-gradient(transparent, rgba(0, 0, 0, 0.375));
	--local-vignette-sm: radial-gradient(transparent, rgba(0, 0, 0, 0.25));
	--local-dot-pattern: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAIklEQVRIiWMYBaNgFIwCKoP9L/+7j4bpKBgFo2DIAQYGBgDwnwLvd3/wDQAAAABJRU5ErkJggg==);
	--local-gradient: linear-gradient(
		calc(90deg + var(--img-angle)),
		rgba(var(--app-theme-value), 0.15) 0%,
		rgba(135, 190, 254, 0.1) 100%
	);

	--app-banner-bg: var(--local-vignette), var(--local-dot-pattern),
		var(--local-gradient);
	--app-banner-bg-sm: var(--local-vignette-sm), var(--local-dot-pattern),
		var(--local-gradient);
	--font-family: "Nunito Sans", "Segoe UI", Tahoma, Geneva, Verdana,
		sans-serif;
	--font-family-mono: "Source Code Pro", "Courier New", Courier, monospace;
	--easing: cubic-bezier(0.83, 0, 0.17, 1);
	--modal-backdrop: hsla(230, 36%, 4%, 95%);
	--img-angle: 11.25deg;
	/*
		plyr config
	*/
	--plyr-audio-controls-background: var(--app-container-bg-primary);
	--plyr-audio-control-color: var(--app-text);
	--plyr-color-main: var(--app-theme-primary);
	/*
		toastify config
	*/
	--toastify-color-dark: var(--app-container-bg-primary);
	--toastify-color-error: var(--app-theme-primary);
	--toastify-font-family: var(--font-family);
	/*
		tooltip config
	*/
	--tooltip-background: var(--app-container-bg-primary);
	--tooltip-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	/*
		progress bar config
	*/
	--progress-easing: cubic-bezier(0.87, 0, 0.13, 1);
	--progress-background: hsl(230, 25%, 35%);
	--progress-shadow: hsl(230, 25%, 23.5%);
	--bar-primary: rgb(13, 104, 216);
	--bar-secondary: rgb(97, 158, 255);
	/*
		status config

	*/

	/*

		border hsla(color,50%,100%)
		bg hsla(color,50%,25%)
		text hsla(color,95%,25%)

	*/

	--status-approved: 148, 100%;
	--status-pending: 43, 100%;
	--status-rejected: 338, 100%;
	--status-royalty: 225, 20%;
}

::selection {
	background: hsla(240, 6%, 75%, 0.75);
	color: var(--app-container-bg-primary);
}

html {
	scroll-behavior: smooth;
}

body {
	overflow-x: hidden;
}

html,
body {
	font-family: var(--font-family);
	font-weight: 500;
	background: var(--app-background);
	color: var(--app-text);
	letter-spacing: -1%;
	text-rendering: optimizeLegibility;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

code {
	font-family: var(--font-family-mono);
	font-size: 1rem;
	padding: 0.25rem;
	border-radius: 0.375rem;
	font-weight: 500;
}

footer {
	width: 100%;
}
